var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { AngularFirestore } from '@angular/fire/firestore';
import { AuthService } from 'app/core/auth.service';
import { FirebaseHelper } from 'app/helpers/firebase.helper';
import { EMPTY, combineLatest } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as moment from 'moment';
import { JourneyManagementObject } from 'app/interfaces/journey-management/journey-management-object.interface';
import * as i0 from "@angular/core";
import * as i1 from "../../core/auth.service";
var AssetManagementService = /** @class */ (function () {
    function AssetManagementService(authService, firestore) {
        this.authService = authService;
        this.firestore = firestore;
        this.collectionName = 'assets';
        this.batchLimit = 250;
        console.debug('AssetManagementService: instantiated');
    }
    AssetManagementService.prototype.chunkArray = function (array, chunkSize) {
        var results = [];
        for (var i = 0; i < array.length; i += chunkSize) {
            results.push(array.slice(i, i + chunkSize));
        }
        return results;
    };
    /* watchLinkedAssetManagementObjects(serviceInformation?: ServiceInformation[], clientKey?: string): Observable<AssetManagementObject[]> {
        let collection: AngularFirestoreCollection<AssetManagementObject>;
        let AssetKeys = [];

        serviceInformation.map((service) => {
            service.linkedAssetsResources.map((linkedAsset) => {
                AssetKeys.push(linkedAsset.asset.asset.key)
            })
        })

        if (AssetKeys.length == 0) {
            return EMPTY;
        }
        
        if (!clientKey && this.authService.appUser.userClient.isJourneyManagementOversightAdmin)
        {
            collection = this.firestore.collection<AssetManagementObject>(this.collectionName, ref => ref.where('key','in',AssetKeys).orderBy('dateInitiated'));
        }
        else {
            const chosenClientKey = clientKey || this.authService.appUser.userDetail.clientKey;

            collection = this.firestore.collection<AssetManagementObject>(this.collectionName, ref => ref.where('clientKey', '==', chosenClientKey).where('key','in',AssetKeys).orderBy('dateInitiated'));
        }

        return collection.snapshotChanges().pipe(
            map(events => {
                return events.map(event => {
                        return FirebaseHelper.convertFromFirebase(event.payload.doc.data() as AssetManagementObject);
                    });
            })
        );
    } */
    AssetManagementService.prototype.watchLinkedAssetManagementObjects = function (journeyKey, historical, clientKey) {
        var _this = this;
        if (!journeyKey) {
            console.warn('Journey key is not provided.');
            return EMPTY;
        }
        var collection = historical ? 'journeysHistory' : 'journeys';
        return this.firestore.doc(collection + "/" + journeyKey)
            .snapshotChanges()
            .pipe(switchMap(function (event) {
            var journeyManagementObject = FirebaseHelper.convertFromFirebase(event.payload.data());
            if (!journeyManagementObject.serviceInformation || journeyManagementObject.serviceInformation.length === 0) {
                console.warn('No linked asset keys found.');
                return EMPTY;
            }
            var assetKeys = journeyManagementObject.serviceInformation.reduce(function (keys, service) {
                if (service.linkedAssetsResources && service.linkedAssetsResources.length > 0) {
                    return keys.concat(service.linkedAssetsResources.map(function (linkedAsset) { return linkedAsset.asset.asset.key; }));
                }
                return keys;
            }, []);
            if (assetKeys.length === 0) {
                console.warn('No linked asset keys found.');
                return EMPTY;
            }
            var assetKeyChunks = _this.chunkArray(assetKeys, 10);
            var observables = assetKeyChunks.map(function (chunk) {
                if (!clientKey && _this.authService.appUser.userClient.isJourneyManagementOversightAdmin) {
                    return _this.firestore.collection(_this.collectionName, function (ref) { return ref.where('key', 'in', chunk); }).valueChanges();
                }
                else {
                    var chosenClientKey_1 = clientKey || _this.authService.appUser.userDetail.clientKey;
                    return _this.firestore.collection(_this.collectionName, function (ref) { return ref.where('clientKey', '==', chosenClientKey_1)
                        .where('key', 'in', chunk); }).valueChanges();
                }
            });
            return combineLatest(observables).pipe(map(function (arrays) { return [].concat.apply([], arrays); }));
        }), catchError(function (error) {
            console.error('Error occurred:', error);
            return EMPTY;
        }));
    };
    AssetManagementService.prototype.watchAssetManagementObjects = function (clientKey) {
        var collection;
        if (!clientKey && this.authService.appUser.userClient.isJourneyManagementOversightAdmin) {
            collection = this.firestore.collection(this.collectionName, function (ref) { return ref.orderBy('dateInitiated'); });
        }
        else {
            var chosenClientKey_2 = clientKey || this.authService.appUser.userDetail.clientKey;
            collection = this.firestore.collection(this.collectionName, function (ref) { return ref.where('clientKey', '==', chosenClientKey_2).orderBy('dateInitiated'); });
        }
        return collection.snapshotChanges().pipe(map(function (events) {
            return events.map(function (event) {
                return FirebaseHelper.convertFromFirebase(event.payload.doc.data());
            });
        }));
    };
    AssetManagementService.prototype.watchAssetManagementObject = function (key) {
        var docRef = this.firestore.collection(this.collectionName).doc(key);
        return docRef.snapshotChanges().pipe(map(function (event) {
            return FirebaseHelper.convertFromFirebase(event.payload.data());
        }));
    };
    // Batch add multiple assets
    AssetManagementService.prototype.addAssetManagementObjects = function (assets) {
        return __awaiter(this, void 0, void 0, function () {
            var clientKey_1, clientName_1, creatorClientKey_1, creatorClientName_1, initiatorKey_1, initiatorName_1, timestamp_1, batch_1, addedAssets_1, error_1;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (assets.length > this.batchLimit) {
                            throw new Error("Exceeded the batch limit of " + this.batchLimit);
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        clientKey_1 = this.authService.appUser.userClient.key;
                        clientName_1 = this.authService.appUser.userClient.name;
                        creatorClientKey_1 = this.authService.appUser.userClient.key;
                        creatorClientName_1 = this.authService.appUser.userClient.name;
                        initiatorKey_1 = this.authService.appUser.userDetail.key;
                        initiatorName_1 = this.authService.appUser.userDetail.firstName + " " + this.authService.appUser.userDetail.lastName;
                        timestamp_1 = moment();
                        batch_1 = this.firestore.firestore.batch();
                        addedAssets_1 = [];
                        assets.forEach(function (asset) {
                            var newId = _this.firestore.createId();
                            asset.key = newId;
                            asset.clientKey = clientKey_1;
                            asset.clientName = clientName_1;
                            asset.creatorClientKey = creatorClientKey_1;
                            asset.creatorClientName = creatorClientName_1;
                            asset.initiatorKey = initiatorKey_1;
                            asset.initiatorName = initiatorName_1;
                            asset.dateInitiated = timestamp_1;
                            asset = FirebaseHelper.convertForFirebase(asset);
                            var newDocRef = _this.firestore.collection(_this.collectionName).doc(newId);
                            batch_1.set(newDocRef.ref, asset);
                            addedAssets_1.push(asset);
                        });
                        return [4 /*yield*/, batch_1.commit()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/, addedAssets_1];
                    case 3:
                        error_1 = _a.sent();
                        console.error('Error creating new asset:', error_1);
                        throw error_1;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    // Add specific asset
    AssetManagementService.prototype.addAssetManagementObject = function (asset) {
        return __awaiter(this, void 0, void 0, function () {
            var newId, newDocRef, error_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        newId = this.firestore.createId();
                        asset.key = newId;
                        asset.clientKey = this.authService.appUser.userClient.key;
                        asset.clientName = this.authService.appUser.userClient.name;
                        asset.creatorClientKey = this.authService.appUser.userClient.key;
                        asset.creatorClientName = this.authService.appUser.userClient.name;
                        asset.initiatorKey = this.authService.appUser.userDetail.key;
                        asset.initiatorName = this.authService.appUser.userDetail.firstName + " " + this.authService.appUser.userDetail.lastName;
                        asset.dateInitiated = moment();
                        asset = FirebaseHelper.convertForFirebase(asset);
                        newDocRef = this.firestore.collection(this.collectionName).doc(newId);
                        return [4 /*yield*/, newDocRef.set(asset)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/, asset];
                    case 2:
                        error_2 = _a.sent();
                        console.error('Error creating new asset:', error_2);
                        throw error_2;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    // Fetch all assets
    AssetManagementService.prototype.getAllAssetManagementObjects = function (clientKey) {
        return __awaiter(this, void 0, void 0, function () {
            var collection, chosenClientKey_3, assets, error_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!clientKey && this.authService.appUser.userClient.isJourneyManagementOversightAdmin) {
                            collection = this.firestore.collection(this.collectionName, function (ref) { return ref.orderBy('dateInitiated'); });
                        }
                        else {
                            chosenClientKey_3 = clientKey || this.authService.appUser.userDetail.clientKey;
                            collection = this.firestore.collection(this.collectionName, function (ref) { return ref.where('clientKey', '==', chosenClientKey_3).orderBy('dateInitiated'); });
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, collection.valueChanges()
                                .pipe(map(function (assetArray) { return assetArray.map(function (asset) { return FirebaseHelper.convertFromFirebase(asset); }); }))
                                .toPromise()];
                    case 2:
                        assets = _a.sent();
                        return [2 /*return*/, assets];
                    case 3:
                        error_3 = _a.sent();
                        console.error('Error fetching all assets:', error_3);
                        throw error_3;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    // Fetch specific asset by its key
    AssetManagementService.prototype.getAssetManagementObject = function (key) {
        return __awaiter(this, void 0, void 0, function () {
            var asset, error_4;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.firestore.doc(this.collectionName + "/" + key)
                                .valueChanges()
                                .pipe(map(function (assetObj) { return FirebaseHelper.convertFromFirebase(assetObj); }))
                                .toPromise()];
                    case 1:
                        asset = _a.sent();
                        return [2 /*return*/, asset];
                    case 2:
                        error_4 = _a.sent();
                        console.error("Error fetching asset with key " + key + ":", error_4);
                        throw error_4;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    // Batch update multiple assets
    AssetManagementService.prototype.updateAssetManagementObjects = function (assets) {
        return __awaiter(this, void 0, void 0, function () {
            var batch_2, error_5;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (assets.length > this.batchLimit) {
                            throw new Error("Exceeded the batch limit of " + this.batchLimit);
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        batch_2 = this.firestore.firestore.batch();
                        assets.forEach(function (asset) {
                            var docRef = _this.firestore.firestore.collection(_this.collectionName).doc(asset.key);
                            asset = FirebaseHelper.convertForFirebase(asset);
                            batch_2.update(docRef, asset);
                        });
                        return [4 /*yield*/, batch_2.commit()];
                    case 2: return [2 /*return*/, _a.sent()];
                    case 3:
                        error_5 = _a.sent();
                        console.error('Error updating assets in batch:', error_5);
                        throw error_5;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    // Update a specific asset
    AssetManagementService.prototype.updateAssetManagementObject = function (asset) {
        return __awaiter(this, void 0, void 0, function () {
            var error_6;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        asset = FirebaseHelper.convertForFirebase(asset);
                        return [4 /*yield*/, this.firestore.collection(this.collectionName).doc(asset.key).update(asset)];
                    case 1: return [2 /*return*/, _a.sent()];
                    case 2:
                        error_6 = _a.sent();
                        console.error('Error updating asset:', error_6);
                        throw error_6;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    // Batch delete multiple assets
    AssetManagementService.prototype.deleteAssetManagementObjects = function (keys) {
        return __awaiter(this, void 0, void 0, function () {
            var batch_3, error_7;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (keys.length > this.batchLimit) {
                            throw new Error("Exceeded the batch limit of " + this.batchLimit);
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        batch_3 = this.firestore.firestore.batch();
                        keys.forEach(function (key) {
                            var docRef = _this.firestore.firestore.collection(_this.collectionName).doc(key);
                            batch_3.delete(docRef);
                        });
                        return [4 /*yield*/, batch_3.commit()];
                    case 2: return [2 /*return*/, _a.sent()];
                    case 3:
                        error_7 = _a.sent();
                        console.error('Error deleting assets in batch:', error_7);
                        throw error_7;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    // Delete a specific asset
    AssetManagementService.prototype.deleteAssetManagementObject = function (key) {
        return __awaiter(this, void 0, void 0, function () {
            var error_8;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.firestore.collection(this.collectionName).doc(key).delete()];
                    case 1: return [2 /*return*/, _a.sent()];
                    case 2:
                        error_8 = _a.sent();
                        console.error("Error deleting asset with key " + key + ":", error_8);
                        throw error_8;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    AssetManagementService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AssetManagementService_Factory() { return new AssetManagementService(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject("project-one-firestore")); }, token: AssetManagementService, providedIn: "root" });
    return AssetManagementService;
}());
export { AssetManagementService };
