import { Inject, Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AuthService } from 'app/core/auth.service';
import { FirebaseHelper } from 'app/helpers/firebase.helper';
import { AssetManagementObject } from 'app/interfaces/asset-management/asset-management-object.interface';
import { EMPTY, Observable, combineLatest } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as moment from 'moment';
import { JourneyManagementObject, ServiceInformation } from 'app/interfaces/journey-management/journey-management-object.interface';
import { FIREBASE_REFERENCES } from 'app/core/firebase/firebase.module';

@Injectable({
    providedIn: 'root'
})
export class AssetManagementService {
    private readonly collectionName: string = 'assets';
    private readonly batchLimit: number = 250;

    constructor(private authService: AuthService, @Inject(FIREBASE_REFERENCES.ONE_FIRESTORE) private readonly firestore: AngularFirestore) {
        console.debug('AssetManagementService: instantiated');
    }

    private chunkArray<T>(array: T[], chunkSize: number): T[][] {
        let results = [];

        for (let i = 0; i < array.length; i += chunkSize) {
            results.push(array.slice(i, i + chunkSize));
        }

        return results;
    }

    /* watchLinkedAssetManagementObjects(serviceInformation?: ServiceInformation[], clientKey?: string): Observable<AssetManagementObject[]> {
        let collection: AngularFirestoreCollection<AssetManagementObject>;
        let AssetKeys = [];

        serviceInformation.map((service) => {
            service.linkedAssetsResources.map((linkedAsset) => {
                AssetKeys.push(linkedAsset.asset.asset.key)
            })
        })

        if (AssetKeys.length == 0) {
            return EMPTY;
        }
        
        if (!clientKey && this.authService.appUser.userClient.isJourneyManagementOversightAdmin)
        {
            collection = this.firestore.collection<AssetManagementObject>(this.collectionName, ref => ref.where('key','in',AssetKeys).orderBy('dateInitiated'));
        }
        else {
            const chosenClientKey = clientKey || this.authService.appUser.userDetail.clientKey;

            collection = this.firestore.collection<AssetManagementObject>(this.collectionName, ref => ref.where('clientKey', '==', chosenClientKey).where('key','in',AssetKeys).orderBy('dateInitiated'));
        }

        return collection.snapshotChanges().pipe(
            map(events => {
                return events.map(event => {
                        return FirebaseHelper.convertFromFirebase(event.payload.doc.data() as AssetManagementObject);
                    });
            })
        );
    } */

    watchLinkedAssetManagementObjects(journeyKey: string, historical: boolean,clientKey?: string): Observable<AssetManagementObject[]> {
        if (!journeyKey) {
            console.warn('Journey key is not provided.');
            return EMPTY;
        }
        const collection = historical ? 'journeysHistory' : 'journeys';
    
        return this.firestore.doc<JourneyManagementObject>(`${collection}/${journeyKey}`)
            .snapshotChanges()
            .pipe(
                switchMap(event => {
                    const journeyManagementObject = FirebaseHelper.convertFromFirebase(event.payload.data() as JourneyManagementObject);

                    if (!journeyManagementObject.serviceInformation || journeyManagementObject.serviceInformation.length === 0) {
                        console.warn('No linked asset keys found.');
                        return EMPTY;
                    }
    
                    const assetKeys = journeyManagementObject.serviceInformation.reduce((keys, service) => {
                        if (service.linkedAssetsResources && service.linkedAssetsResources.length > 0) {
                            return keys.concat(service.linkedAssetsResources.map(linkedAsset => linkedAsset.asset.asset.key));
                        }

                        return keys;
                    }, []);
    
                    if (assetKeys.length === 0) {
                        console.warn('No linked asset keys found.');
                        return EMPTY;
                    }
    
                    const assetKeyChunks = this.chunkArray(assetKeys, 10);

                    const observables = assetKeyChunks.map(chunk => {
                        if (!clientKey && this.authService.appUser.userClient.isJourneyManagementOversightAdmin) {
                            return this.firestore.collection<AssetManagementObject>(
                                this.collectionName, ref => ref.where('key', 'in', chunk)
                            ).valueChanges();
                        }
                        else {
                            const chosenClientKey = clientKey || this.authService.appUser.userDetail.clientKey;

                            return this.firestore.collection<AssetManagementObject>(
                                this.collectionName, 
                                ref => ref.where('clientKey', '==', chosenClientKey)
                                          .where('key', 'in', chunk)
                            ).valueChanges();
                        }
                    });
    
                    return combineLatest(observables).pipe(
                        map(arrays => [].concat(...arrays))
                    );
                }),
                catchError(error => {
                    console.error('Error occurred:', error);
                    return EMPTY;
                })
            );
    }

    watchAssetManagementObjects(clientKey?: string): Observable<AssetManagementObject[]> {
        let collection: AngularFirestoreCollection<AssetManagementObject>;
        
        if (!clientKey && this.authService.appUser.userClient.isJourneyManagementOversightAdmin)
        {
            collection = this.firestore.collection<AssetManagementObject>(this.collectionName, ref => ref.orderBy('dateInitiated'));
        }
        else {
            const chosenClientKey = clientKey || this.authService.appUser.userDetail.clientKey;

            collection = this.firestore.collection<AssetManagementObject>(this.collectionName, ref => ref.where('clientKey', '==', chosenClientKey).orderBy('dateInitiated'));
        }

        return collection.snapshotChanges().pipe(
            map(events => {
                return events.map(event => {
                        return FirebaseHelper.convertFromFirebase(event.payload.doc.data() as AssetManagementObject);
                    });
            })
        );
    }

    watchAssetManagementObject(key: string): Observable<AssetManagementObject> {
        const docRef = this.firestore.collection(this.collectionName).doc<AssetManagementObject>(key);

        return docRef.snapshotChanges().pipe(
            map(event => {
                return FirebaseHelper.convertFromFirebase(event.payload.data() as AssetManagementObject);
            })
        );
    }

    // Batch add multiple assets
    async addAssetManagementObjects(assets: AssetManagementObject[]): Promise<AssetManagementObject[]> {
        if (assets.length > this.batchLimit) {
            throw new Error(`Exceeded the batch limit of ${this.batchLimit}`);
        }

        try {
            const clientKey = this.authService.appUser.userClient.key;
            const clientName = this.authService.appUser.userClient.name;
            const creatorClientKey = this.authService.appUser.userClient.key;
            const creatorClientName = this.authService.appUser.userClient.name;
            const initiatorKey = this.authService.appUser.userDetail.key;
            const initiatorName = `${this.authService.appUser.userDetail.firstName} ${this.authService.appUser.userDetail.lastName}`;
            const timestamp = moment();

            const batch = this.firestore.firestore.batch();

            const addedAssets: AssetManagementObject[] = [];

            assets.forEach(asset => {
                const newId = this.firestore.createId();

                asset.key = newId;
                asset.clientKey = clientKey;
                asset.clientName = clientName;
                asset.creatorClientKey = creatorClientKey;
                asset.creatorClientName = creatorClientName;
                asset.initiatorKey = initiatorKey;
                asset.initiatorName = initiatorName;
                asset.dateInitiated = timestamp;

                asset = FirebaseHelper.convertForFirebase(asset);

                const newDocRef = this.firestore.collection(this.collectionName).doc(newId);

                batch.set(newDocRef.ref, asset);

                addedAssets.push(asset);
            });

            await batch.commit();

            return addedAssets;
        }
        catch (error) {
            console.error('Error creating new asset:', error);
            throw error;
        }
    }

    // Add specific asset
    async addAssetManagementObject(asset: AssetManagementObject): Promise<AssetManagementObject> {
        try {
            const newId = this.firestore.createId();

            asset.key = newId;
            asset.clientKey = this.authService.appUser.userClient.key;
            asset.clientName = this.authService.appUser.userClient.name;
            asset.creatorClientKey = this.authService.appUser.userClient.key;
            asset.creatorClientName = this.authService.appUser.userClient.name;
            asset.initiatorKey = this.authService.appUser.userDetail.key;
            asset.initiatorName = `${this.authService.appUser.userDetail.firstName} ${this.authService.appUser.userDetail.lastName}`;
            asset.dateInitiated = moment();

            asset = FirebaseHelper.convertForFirebase(asset);

            const newDocRef = this.firestore.collection(this.collectionName).doc(newId);

            await newDocRef.set(asset);

            return asset;
        }
        catch (error) {
            console.error('Error creating new asset:', error);
            throw error;
        }
    }

    // Fetch all assets
    async getAllAssetManagementObjects(clientKey?: string): Promise<AssetManagementObject[]> {
        let collection: AngularFirestoreCollection<AssetManagementObject>;
        
        if (!clientKey && this.authService.appUser.userClient.isJourneyManagementOversightAdmin)
        {
            collection = this.firestore.collection<AssetManagementObject>(this.collectionName, ref => ref.orderBy('dateInitiated'));
        }
        else {
            const chosenClientKey = clientKey || this.authService.appUser.userDetail.clientKey;

            collection = this.firestore.collection<AssetManagementObject>(this.collectionName, ref => ref.where('clientKey', '==', chosenClientKey).orderBy('dateInitiated'));
        }

        try {
            const assets = await collection.valueChanges()
                .pipe(
                    map(assetArray => assetArray.map(asset => FirebaseHelper.convertFromFirebase(asset)))
                )
                .toPromise();

            return assets;
        }
        catch (error) {
            console.error('Error fetching all assets:', error);
            throw error;
        }
    }

    // Fetch specific asset by its key
    async getAssetManagementObject(key: string): Promise<AssetManagementObject> {
        try {
            const asset = await this.firestore.doc<AssetManagementObject>(`${this.collectionName}/${key}`)
                .valueChanges()
                .pipe(
                    map(assetObj => FirebaseHelper.convertFromFirebase(assetObj))
                )
                .toPromise();

            return asset;
        }
        catch (error) {
            console.error(`Error fetching asset with key ${key}:`, error);
            throw error;
        }
    }

    // Batch update multiple assets
    async updateAssetManagementObjects(assets: AssetManagementObject[]): Promise<void> {
        if (assets.length > this.batchLimit) {
            throw new Error(`Exceeded the batch limit of ${this.batchLimit}`);
        }

        try {
            const batch = this.firestore.firestore.batch();

            assets.forEach(asset => {
                const docRef = this.firestore.firestore.collection(this.collectionName).doc(asset.key);

                asset = FirebaseHelper.convertForFirebase(asset);

                batch.update(docRef, asset);
            });

            return await batch.commit();
        }
        catch (error) {
            console.error('Error updating assets in batch:', error);
            throw error;
        }
    }

    // Update a specific asset
    async updateAssetManagementObject(asset: AssetManagementObject): Promise<void> {
        try {
            asset = FirebaseHelper.convertForFirebase(asset);

            return await this.firestore.collection(this.collectionName).doc(asset.key).update(asset);
        }
        catch (error) {
            console.error('Error updating asset:', error);
            throw error;
        }
    }

    // Batch delete multiple assets
    async deleteAssetManagementObjects(keys: string[]): Promise<void> {
        if (keys.length > this.batchLimit) {
            throw new Error(`Exceeded the batch limit of ${this.batchLimit}`);
        }

        try {
            const batch = this.firestore.firestore.batch();

            keys.forEach(key => {
                const docRef = this.firestore.firestore.collection(this.collectionName).doc(key);

                batch.delete(docRef);
            });

            return await batch.commit();
        }
        catch (error) {
            console.error('Error deleting assets in batch:', error);
            throw error;
        }
    }

    // Delete a specific asset
    async deleteAssetManagementObject(key: string): Promise<void> {
        try {
            return await this.firestore.collection(this.collectionName).doc(key).delete();
        }
        catch (error) {
            console.error(`Error deleting asset with key ${key}:`, error);
            throw error;
        }
    }
}