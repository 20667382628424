var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { AngularFirestore } from '@angular/fire/firestore';
import { AuthService } from 'app/core/auth.service';
import { EMPTY, Observable, combineLatest, merge } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { JourneyManagementObject } from 'app/interfaces/journey-management/journey-management-object.interface';
import * as i0 from "@angular/core";
import * as i1 from "../../core/auth.service";
var ResourceManagementService = /** @class */ (function () {
    function ResourceManagementService(authService, firestore) {
        this.authService = authService;
        this.firestore = firestore;
        this.allUserDetails = [];
        this.linkedUserDetails = [];
        console.debug('ResourceManagementService: instantiated');
    }
    ResourceManagementService.prototype.chunkArray = function (array, chunkSize) {
        var results = [];
        for (var i = 0; i < array.length; i += chunkSize) {
            results.push(array.slice(i, i + chunkSize));
        }
        return results;
    };
    /* watchLinkedResourceManagementObjects(serviceInformation?: ServiceInformation[]): Observable<UserDetail[]> {
        let resourceKeys = [];

        serviceInformation.map((service) => {
            service.linkedAssetsResources.map((linkedResources) => {
                linkedResources.associatedResources.map((resource) => {
                    resourceKeys.push(resource.resource.key);
                })
            })
        })

        if (resourceKeys.length == 0) {
            return EMPTY;
        }


        let chunks = this.chunkArray(resourceKeys, 10);

        const observables = chunks.map(chunk => {
            return this.firestore.collection<UserDetail>('users', ref => ref.where('key', 'in', chunk))
                .snapshotChanges()
                .pipe(map(events => {
                    return events.map<UserDetail>(event => {
                        return event.payload.doc.data() as UserDetail;
                    });
                }));
            });

        let AllObservables = merge(...observables)

        return AllObservables.pipe(
            tap((updatedBatch: UserDetail[]) => {
                for (const updatedUser of updatedBatch) {
                    const index = this.linkedUserDetails.findIndex(user => user.key === updatedUser.key);

                    if (index === -1) {
                        this.linkedUserDetails.push(updatedUser);
                    }
                    else {
                        this.linkedUserDetails[index] = updatedUser;
                    }
                }
            }),
            map(() => {
                return [...this.linkedUserDetails];
            })
        );
    } */
    ResourceManagementService.prototype.watchLinkedResourceManagementObjects = function (journeyKey, historical, clientKey) {
        var _this = this;
        if (!journeyKey) {
            console.warn('Journey key is not provided.');
            return EMPTY;
        }
        var collection = (historical == true) ? 'journeysHistory' : 'journeys';
        return this.firestore.doc(collection + "/" + journeyKey)
            .snapshotChanges()
            .pipe(switchMap(function (journey) {
            var journeyManagementObject = journey.payload.data();
            if (!journeyManagementObject.serviceInformation) {
                console.warn('No service information found.');
                return EMPTY;
            }
            var userDetailObjects = journeyManagementObject.serviceInformation.reduce(function (acc, service) {
                if (service.linkedAssetsResources && service.linkedAssetsResources.length > 0) {
                    var userDetails = service.linkedAssetsResources.reduce(function (resAcc, linkedAsset) {
                        if (linkedAsset.associatedResources && linkedAsset.associatedResources.length > 0) {
                            return resAcc.concat(linkedAsset.associatedResources.map(function (res) { return res.resource; }));
                        }
                        return resAcc;
                    }, []);
                    return acc.concat(userDetails);
                }
                return acc;
            }, []);
            if (userDetailObjects.length === 0) {
                console.warn('No user details found.');
                return EMPTY;
            }
            var userDetailKeys = userDetailObjects.map(function (userDetail) { return userDetail.key; });
            var userDetailKeyChunks = _this.chunkArray(userDetailKeys, 10);
            var observables = userDetailKeyChunks.map(function (chunk) {
                return _this.firestore.collection('users', function (ref) { return ref.where('key', 'in', chunk); }).valueChanges();
            });
            return combineLatest(observables).pipe(map(function (arrays) { return [].concat.apply([], arrays); }));
        }), catchError(function (error) {
            console.error('Error occurred:', error);
            return EMPTY;
        }));
    };
    ResourceManagementService.prototype.watchResourceManagementObjects = function (clientKey) {
        var _this = this;
        var collection;
        if (!clientKey && this.authService.appUser.userClient.isJourneyManagementOversightAdmin) {
            collection = this.firestore.collection('responseServices');
        }
        else {
            var chosenClientKey_1 = clientKey || this.authService.appUser.userDetail.clientKey;
            collection = this.firestore.collection('responseServices', function (ref) { return ref.where('clientKey', '==', chosenClientKey_1); });
        }
        return collection.snapshotChanges()
            .pipe(map(function (events) { return events.map(function (a) { return a.payload.doc.data(); }); }), tap(function () {
            _this.allUserDetails = [];
        }), map(function (responseServices) {
            var combined = [];
            for (var _i = 0, responseServices_1 = responseServices; _i < responseServices_1.length; _i++) {
                var responseService = responseServices_1[_i];
                if (responseService.category == 'requestDriver') {
                    for (var _a = 0, _b = responseService.drivers; _a < _b.length; _a++) {
                        var driver = _b[_a];
                        if (!combined.includes(driver.key)) {
                            combined.push(driver.key);
                        }
                    }
                }
            }
            return _this.chunkArray(combined, 10);
        }), mergeMap(function (chunkedKeys) {
            var observables = chunkedKeys.map(function (chunk) {
                return _this.firestore.collection('users', function (ref) { return ref.where('key', 'in', chunk); })
                    .snapshotChanges()
                    .pipe(map(function (events) {
                    return events.map(function (event) {
                        return event.payload.doc.data();
                    });
                }));
            });
            return merge.apply(void 0, observables);
        }), tap(function (updatedBatch) {
            var _loop_1 = function (updatedUser) {
                var index = _this.allUserDetails.findIndex(function (user) { return user.key === updatedUser.key; });
                if (index === -1) {
                    _this.allUserDetails.push(updatedUser);
                }
                else {
                    _this.allUserDetails[index] = updatedUser;
                }
            };
            for (var _i = 0, updatedBatch_1 = updatedBatch; _i < updatedBatch_1.length; _i++) {
                var updatedUser = updatedBatch_1[_i];
                _loop_1(updatedUser);
            }
        }), map(function () {
            return _this.allUserDetails.slice();
        }));
    };
    ResourceManagementService.prototype.watchResourceManagementObject = function (key) {
        var docRef = this.firestore.collection('users').doc(key);
        return docRef.snapshotChanges().pipe(map(function (event) {
            return event.payload.data();
        }));
    };
    // Fetch all resources
    ResourceManagementService.prototype.getAllResourceManagementObjects = function (clientKey) {
        return __awaiter(this, void 0, void 0, function () {
            var collection, chosenClientKey_2, resources_1, responseServicesSnapshot, responseServices_3, combined, _i, responseServices_2, responseService, _a, _b, driver, chunkedKeys, promises, snapshots, _c, snapshots_1, snapshot, error_1;
            var _this = this;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        if (!clientKey && this.authService.appUser.userClient.isJourneyManagementOversightAdmin) {
                            collection = this.firestore.collection('responseServices');
                        }
                        else {
                            chosenClientKey_2 = clientKey || this.authService.appUser.userDetail.clientKey;
                            collection = this.firestore.collection('responseServices', function (ref) { return ref.where('clientKey', '==', chosenClientKey_2); });
                        }
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 4, , 5]);
                        resources_1 = [];
                        return [4 /*yield*/, collection.get().toPromise()];
                    case 2:
                        responseServicesSnapshot = _d.sent();
                        responseServices_3 = [];
                        responseServicesSnapshot.forEach(function (doc) {
                            responseServices_3.push(doc.data());
                        });
                        combined = [];
                        for (_i = 0, responseServices_2 = responseServices_3; _i < responseServices_2.length; _i++) {
                            responseService = responseServices_2[_i];
                            for (_a = 0, _b = responseService.drivers; _a < _b.length; _a++) {
                                driver = _b[_a];
                                if (!combined.includes(driver.key)) {
                                    combined.push(driver.key);
                                }
                            }
                        }
                        chunkedKeys = this.chunkArray(combined, 10);
                        promises = chunkedKeys.map(function (chunk) {
                            return _this.firestore.collection('users', function (ref) { return ref.where('key', 'in', chunk); }).get().toPromise();
                        });
                        return [4 /*yield*/, Promise.all(promises)];
                    case 3:
                        snapshots = _d.sent();
                        for (_c = 0, snapshots_1 = snapshots; _c < snapshots_1.length; _c++) {
                            snapshot = snapshots_1[_c];
                            snapshot.forEach(function (doc) {
                                resources_1.push(doc.data());
                            });
                        }
                        return [2 /*return*/, resources_1];
                    case 4:
                        error_1 = _d.sent();
                        console.error('Error fetching all resources:', error_1);
                        throw error_1;
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    // Fetch specific resource by its key
    ResourceManagementService.prototype.getResourceManagementObject = function (key) {
        return __awaiter(this, void 0, void 0, function () {
            var resource, error_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.firestore.doc("users/" + key)
                                .valueChanges()
                                .toPromise()];
                    case 1:
                        resource = _a.sent();
                        return [2 /*return*/, resource];
                    case 2:
                        error_2 = _a.sent();
                        console.error("Error fetching resource with key " + key + ":", error_2);
                        throw error_2;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ResourceManagementService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ResourceManagementService_Factory() { return new ResourceManagementService(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject("project-one-firestore")); }, token: ResourceManagementService, providedIn: "root" });
    return ResourceManagementService;
}());
export { ResourceManagementService };
